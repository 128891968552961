(function ($) {
    "use strict";
    var _rtl = false
    if ($('body').css('direction') == 'rtl') {
        _rtl = true
    }

    if ($('.main-header li.dropdown ul').length) {
        $('.main-header .navigation li.dropdown').append('<div class="dropdown-btn"><i class="fa fa-angle-down"></i></div>');
    }

    if ($('.mobile-menu').length) {
        var mobileMenuContent = $('.main-header .main-menu .navigation').html();
        $('.mobile-menu .navigation').append(mobileMenuContent);
        $('.mobile-menu .close-btn').on('click', function () {
            $('body').removeClass('mobile-menu-visible');
        });

        //Dropdown Button
        $('.mobile-menu li.dropdown .dropdown-btn').on('click', function () {
            $(this).prev('ul').slideToggle(500);
            $(this).toggleClass('active');
        });

        //Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function () {
            $('body').addClass('mobile-menu-visible');
        });

        //Menu Toggle Btn
        $('.mobile-menu .menu-backdrop, .mobile-menu .close-btn').on('click', function () {
            $('body').removeClass('mobile-menu-visible');
        });
    }

    if ($('.search-btn').length) {
        $('.search-btn').on('click', function () {
            $('.main-header').addClass('mobile-search-active');
        });
        $('.close-search, .search-back-drop').on('click', function () {
            $('.main-header').removeClass('mobile-search-active');
        });
    }

    if ($('.destination-carousel').length) {
        $('.destination-carousel').owlCarousel({
            loop: true,
            lazyLoad: true,
            margin: 20,
            nav: false,
            dots: false,
            rtl: _rtl,
            smartSpeed: 500,
            autoHeight: true,
            autoplay: true,
            autoplayTimeout: 3000,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1024: {
                    items: 3
                },
                1200: {
                    items: 4
                },
            }
        });
    }

    if ($('.clients-carousel').length) {
        $('.clients-carousel').owlCarousel({
            loop: true,
            margin: 30,
            nav: false,
            rtl: _rtl,
            smartSpeed: 400,
            autoplay: true,
            navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 2
                },
                600: {
                    items: 3
                },
                768: {
                    items: 4
                },
                1023: {
                    items: 5
                },
            }
        });
    }

    $('.testimonial-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        rtl: _rtl,
        smartSpeed: 400,
        autoplay: true,
        lazyLoad: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            992: {
                items: 3
            },
        }
    });

    $('.proj-show-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        rtl: _rtl,
        dots: false,
        dotsContainer: '.interior',
        rewind: true,
        center: true,
        lazyLoad: true,
        items: 1,

    }).on('changed.owl.carousel', function (e) {
        $('.proj-thumbs-carousel').trigger('to.owl.carousel', [e.item.index - 2, 200, !0])
    })

    // Projects Thumbs Carousel
    $('.proj-thumbs-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        rtl: _rtl,
        center: true,
        rewind: true,
        autoWidth: true,
        lazyLoad: true,
        items: 1,
    }).on('click', '.owl-item .item', function () {
        var x = $(this).parent().index()
        $('.proj-show-carousel').trigger('to.owl.carousel', [x - 2, 200, !0])
        $(this).parent().addClass('center').siblings().removeClass('center')
    })

    //Buttons do not have an accessible name and owl-dot is a button -- this to improve speed of website
    $('.owl-carousel .owl-dots .owl-dot').attr('aria-label', 'owl-dot')

    if ($('.scroll-to-target').length) {
        $(".scroll-to-target").on('click', function () {
            var target = $(this).attr('data-target');
            // animate
            $('html, body').animate({
                scrollTop: $(target).offset().top
            }, 1500);

        });
    }

    if ($('.wow').length) {
        var wow = new WOW(
            {
                boxClass: 'wow',
                animateClass: 'animated',
                offset: 0,
                mobile: false,
                live: true
            }
        );
        wow.init();
    }

    $(".quantity-box .add").on('click', function () {
        if ($(this).prev().val() < 999) {
            $(this)
                .prev()
                .val(+$(this).prev().val() + 1);
        }
    });

    $(".quantity-box .sub").on('click', function () {
        if ($(this).next().val() > 1) {
            if ($(this).next().val() > 1)
                $(this)
                    .next()
                    .val(+$(this).next().val() - 1);
        }
    });

    if ($('.accordion-box').length) {
        $(".accordion-box").on('click', '.acc-btn', function () {

            var outerBox = $(this).parents('.accordion-box');
            var target = $(this).parents('.accordion');

            if ($(this).hasClass('active') !== true) {
                $(outerBox).find('.accordion .acc-btn').removeClass('active ');
            }

            if ($(this).next('.acc-content').is(':visible')) {
                return false;
            } else {
                $(this).addClass('active');
                $(outerBox).children('.accordion').removeClass('active-block');
                $(outerBox).find('.accordion').children('.acc-content').slideUp(300);
                target.addClass('active-block');
                $(this).next('.acc-content').slideDown(300);
            }
        });
    }

    $(window).on('scroll', function () {
        var windowPos = $(window).scrollTop();
        var scrollLink = $('.scroll-to-top');
        if (windowPos > 100) {
            scrollLink.fadeIn(300);
        } else {
            scrollLink.fadeOut(300);
        }
    });

    $(".phoneInput").intlTelInput({
        hiddenInput: "Mobile",
        utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js",
        nationalMode: true,
        separateDialCode: true,
        initialCountry: "tr",
        preferredCountries: [
            "SA",
            "TR",
            "KW",
            "IQ",
            "QA",
            "BH",
            "SY",
            "EG",
            "OM",
        ],
    });

    if($('.add-to-cart').length){
        $(".add-to-cart").on('click', function () {
            var itemList = JSON.parse(localStorage.getItem("cart") || "[]")
            let id = $(this).data('id')
            const index = itemList.findIndex(element => (element.id == id))
            if(index!=-1) {
                itemList[index].quantity = itemList[index].quantity + 1
            }else{
                let data = {
                    id : $(this).data('id'),
                    title : $(this).parent().parent().find('.content').find('a').text(),
                    price : $(this).parent().parent().find('.content').find('.price').text(),
                    url : $(this).parent().parent().find('.content').find('a').prop('href'),
                    quantity : 1,
                }

                console.log("data=",data)

                itemList.push(data);
                if(_rtl){
                    toastr.success('تم إضافة المنتج بنجاح')
                }else{
                    toastr.success('the product have added successfully')
                }
            }

            localStorage.setItem("cart", JSON.stringify(itemList))

            window.dispatchEvent(new CustomEvent('localstorage-changed', {
                detail: {
                    storage: localStorage.getItem('cart')
                }
            }));
        });
    }
})(window.jQuery);
